import React from 'react'

export const LogoVertical = ({ maxHeight="98vh", maxWidth="100%" }) => (
  <svg width="auto" height="98vh" style={{maxHeight, maxWidth, width: 'auto'}} viewBox="0 0 79 720" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39.0799 24C50.6799 24 57.9999 31.62 57.9999 44C57.9999 56.38 51.7899 62.85 41.2199 62.85V50.29C45.4299 50.21 47.5799 48.14 47.5799 44.74C47.5799 40.74 44.8399 38.61 39.1499 38.61C33.4599 38.61 30.7199 40.9 30.7199 44.52C30.7199 48.14 32.7899 50.29 36.9299 50.36V62.85C26.1399 62.85 20.2999 56.2 20.2999 43.56C20.2999 30.92 27.9199 24 39.0799 24Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M37.0799 109.86V85.25C31.8299 85.62 29.8399 87.76 29.8399 91.75C29.8399 95.08 31.1699 97.15 34.2699 97.37V109.86C25.6199 109.71 20.2699 103.28 20.2699 91.08C20.2699 76.97 27.8099 70.54 38.7499 70.54C51.3199 70.54 57.9699 78.45 57.9699 91.23C57.9699 102.84 52.1999 109.86 40.4499 109.86H37.0799ZM44.0799 97.37C47.4099 97.37 49.0799 95.3 49.0799 91.75C49.0799 88.2 47.4499 86.13 43.0799 85.47V97.37H44.0799Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 118.21V132.84L47.6499 132.4V132.55C54.0099 134.25 57.9999 138.24 57.9999 146.37C57.9999 155.37 52.8999 160.19 43.7299 160.19H20.8899V145.56H40.0399C44.0399 145.56 45.7999 143.41 45.7999 139.79C45.7999 135.5 43.2199 132.84 37.8899 132.84H20.8899V118.21H57.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M33.76 172.09H46.39V167.09H57.39V171.09C57.39 173.09 58.39 173.97 61.01 174.19L65.67 174.71V186.71H57.41V197.5H46.41V186.73H35.16C32.16 186.73 31.16 188.13 31.16 192.27C31.1619 193.88 31.3091 195.486 31.6 197.07H21.12C20.5406 194.302 20.2656 191.478 20.3 188.65C20.3 176.38 25.48 172.09 33.76 172.09Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 205.79V220.43L47.2099 220V220.15C53.7099 221.63 57.9999 225.15 57.9999 232.15C57.9999 239.83 53.2699 243.53 43.4399 243.53C40.7799 243.53 37.0799 243.31 35.0099 243.16V230.55C36.7099 230.7 38.7099 230.7 39.8199 230.7C44.0299 230.7 45.7299 229.15 45.7299 226.41C45.7299 222.79 42.7299 220.41 36.1899 220.41H20.8899V205.79H57.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M30.9499 251.32C36.7099 251.32 40.9499 255.24 40.9499 264.32V276H43.5699C46.9699 276 48.4399 274.23 48.4399 270.38C48.4399 267.13 46.9699 265.38 44.4399 265.38C44.2899 265.38 43.6999 265.38 42.9599 265.45V252.65C43.7651 252.504 44.5817 252.431 45.3999 252.43C53.1599 252.43 57.9699 258.57 57.9699 271.13C57.9699 284.36 52.0599 290.65 42.3699 290.65H20.8899V276C23.2128 276.282 25.55 276.429 27.8899 276.44V276.37C23.3099 275.26 20.3499 271.64 20.3499 264.84C20.2999 255.83 24.6599 251.32 30.9499 251.32ZM34.0499 276H35.0499V268.46C35.0499 266.02 33.8699 264.62 32.0999 264.62C29.8799 264.62 28.6199 266.39 28.6199 269.35C28.5699 273.5 30.5699 276 34.0499 276Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M72.1199 299.37V314H20.8899V299.37H72.1199Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 344V358.63L47.7999 358.26V358.34C53.8599 360.03 57.9999 363.81 57.9999 370.68C57.9999 377.55 54.5999 381.91 48.2399 382.8V382.87C54.0099 384.35 57.9999 388.34 57.9999 395.22C57.9999 403.42 52.9999 407.56 44.1799 407.56H20.8899V392.94H40.4799C43.8799 392.94 45.7999 391.38 45.7999 388.58C45.7999 385.32 43.2899 383.03 38.0399 383.03H20.8899V368.4H40.4799C43.8799 368.4 45.7999 366.92 45.7999 364.04C45.7999 360.93 43.2199 358.64 37.8899 358.64H20.8899V344H57.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M30.9499 415.77C36.7099 415.77 40.9499 419.69 40.9499 428.77V440.45H43.5699C46.9699 440.45 48.4399 438.68 48.4399 434.83C48.4399 431.58 46.9699 429.83 44.4399 429.83C44.2899 429.83 43.6999 429.83 42.9599 429.9V417.1C43.7651 416.954 44.5817 416.881 45.3999 416.88C53.1599 416.88 57.9699 423.02 57.9699 435.58C57.9699 448.81 52.0599 455.1 42.3699 455.1H20.8899V440.46C23.2128 440.742 25.55 440.889 27.8899 440.9V440.83C23.3099 439.72 20.3499 436.1 20.3499 429.3C20.2999 420.28 24.6599 415.77 30.9499 415.77ZM34.0499 440.46H35.0499V432.92C35.0499 430.48 33.8699 429.08 32.0999 429.08C29.8799 429.08 28.6199 430.85 28.6199 433.81C28.5699 438 30.5699 440.46 34.0499 440.46V440.46Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 463.74V478.38L47.2099 477.93V478.08C53.7099 479.56 57.9999 483.03 57.9999 490.08C57.9999 497.77 53.2699 501.47 43.4399 501.47C40.7799 501.47 37.0799 501.25 35.0099 501.1V488.5C36.7099 488.65 38.7099 488.65 39.8199 488.65C44.0299 488.65 45.7299 487.1 45.7299 484.36C45.7299 480.74 42.7299 478.36 36.1899 478.36H20.8899V463.74H57.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M18.31 551C10.1 551 5 543.91 5 529.79C5 514.86 8.7 508.21 14.24 508.21C17.57 508.21 19.93 510.35 20.3 514.49H20.45C21.0819 513.36 22.0054 512.421 23.1241 511.77C24.2428 511.12 25.5158 510.781 26.81 510.79C28.4761 510.719 30.114 511.236 31.4374 512.251C32.7609 513.265 33.6856 514.713 34.05 516.34H34.12C35.0149 514.243 36.5282 512.468 38.4578 511.253C40.3875 510.038 42.6419 509.44 44.92 509.54C52.82 509.54 58 515.82 58 528.09C58.1017 531.877 57.449 535.647 56.08 539.18H56.15C56.6163 538.492 57.2295 537.916 57.9455 537.494C58.6615 537.072 59.4623 536.815 60.29 536.74C63.1 536.74 65.09 539.47 65.09 545.24C65.109 546.699 65.012 548.157 64.8 549.6H56C56.0897 548.937 56.1398 548.269 56.15 547.6C56.15 545.38 55.26 544.35 53.79 544.35C52.9531 544.432 52.1318 544.63 51.35 544.94C49.4043 546.088 47.179 546.677 44.92 546.64C36.92 546.64 31.92 540.28 31.92 528.09C31.9237 526.211 32.0473 524.333 32.29 522.47C31.7428 522.177 31.1308 522.025 30.51 522.03C29.18 522.03 28.37 523.03 28.37 524.76V536.81C28.36 546.38 24.37 551 18.31 551ZM15.57 536.54C17.2 536.54 18.09 535.29 18.09 531.89V526.12C18.09 523.98 17.09 522.58 15.42 522.58C13.28 522.58 12.1 525.09 12.1 529.67C12.1 534.25 13.36 536.54 15.57 536.54ZM44.92 523.31C41.44 523.31 39.59 525.09 39.59 528.12C39.59 531.15 41.44 532.92 44.92 532.92C48.4 532.92 50.24 531.22 50.24 528.12C50.24 525.02 48.46 523.31 44.92 523.31Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 558.94V573.57H20.8899V558.94H57.4099ZM70.7099 558.94V573.65H60.7099V558.94H70.7099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M57.4099 582.81V597.45L47.6499 597V597.15C54.0099 598.85 57.9999 602.84 57.9999 610.97C57.9999 619.97 52.8999 624.8 43.7299 624.8H20.8899V610.16H40.0399C44.0399 610.16 45.7999 608.02 45.7999 604.4C45.7999 600.11 43.2199 597.45 37.8899 597.45H20.8899V582.81H57.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M30.9499 633C36.7099 633 40.9499 636.92 40.9499 646V657.68H43.5699C46.9699 657.68 48.4399 655.9 48.4399 652.06C48.4399 648.81 46.9699 647.06 44.4399 647.06C43.9455 647.06 43.4515 647.087 42.9599 647.14V634.33C43.7644 634.179 44.5814 634.106 45.3999 634.11C53.1599 634.11 57.9699 640.24 57.9699 652.81C57.9699 666.04 52.0599 672.32 42.3699 672.32H20.8899V657.69C23.2128 657.972 25.55 658.119 27.8899 658.13V658.06C23.3099 656.95 20.3499 653.33 20.3499 646.53C20.2999 637.51 24.6599 633 30.9499 633ZM34.0499 657.69H35.0499V650.15C35.0499 647.71 33.8699 646.3 32.0999 646.3C29.8799 646.3 28.6199 648.08 28.6199 651.03C28.5699 655.17 30.5699 657.69 34.0499 657.69V657.69Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M72.1199 681V695.64H20.8899V681H72.1199Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  </svg>
)

export const LogoVerticalRight = ({ maxHeight="98vh", maxWidth="100%" }) => (
  <svg width="auto" height="98vh" viewBox="0 0 69 673" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M35.0799 1C46.6799 1 53.9999 8.62 53.9999 21C53.9999 33.38 47.7899 39.85 37.2199 39.85V27.29C41.4299 27.21 43.5799 25.14 43.5799 21.74C43.5799 17.74 40.8399 15.61 35.1499 15.61C29.4599 15.61 26.7199 17.9 26.7199 21.52C26.7199 25.14 28.7899 27.29 32.9299 27.36V39.85C22.1399 39.85 16.2999 33.2 16.2999 20.56C16.2999 7.92 23.9199 1 35.0799 1Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M33.0799 86.86V62.25C27.8299 62.62 25.8399 64.76 25.8399 68.75C25.8399 72.08 27.1699 74.15 30.2699 74.37V86.86C21.6199 86.71 16.2699 80.28 16.2699 68.08C16.2699 53.97 23.8099 47.54 34.7499 47.54C47.3199 47.54 53.9699 55.45 53.9699 68.23C53.9699 79.84 48.1999 86.86 36.4499 86.86H33.0799ZM40.0799 74.37C43.4099 74.37 45.0799 72.3 45.0799 68.75C45.0799 65.2 43.4499 63.13 39.0799 62.47V74.37H40.0799Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 95.21V109.84L43.6499 109.4V109.55C50.0099 111.25 53.9999 115.24 53.9999 123.37C53.9999 132.37 48.8999 137.19 39.7299 137.19H16.8899V122.56H36.0399C40.0399 122.56 41.7999 120.41 41.7999 116.79C41.7999 112.5 39.2199 109.84 33.8899 109.84H16.8899V95.21H53.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M29.76 149.09H42.39V144.09H53.39V148.09C53.39 150.09 54.39 150.97 57.01 151.19L61.67 151.71V163.71H53.41V174.5H42.41V163.73H31.16C28.16 163.73 27.16 165.13 27.16 169.27C27.1619 170.88 27.3091 172.486 27.6 174.07H17.12C16.5406 171.302 16.2656 168.478 16.3 165.65C16.3 153.38 21.48 149.09 29.76 149.09Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 182.79V197.43L43.2099 197V197.15C49.7099 198.63 53.9999 202.15 53.9999 209.15C53.9999 216.83 49.2699 220.53 39.4399 220.53C36.7799 220.53 33.0799 220.31 31.0099 220.16V207.55C32.7099 207.7 34.7099 207.7 35.8199 207.7C40.0299 207.7 41.7299 206.15 41.7299 203.41C41.7299 199.79 38.7299 197.41 32.1899 197.41H16.8899V182.79H53.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M26.9499 228.32C32.7099 228.32 36.9499 232.24 36.9499 241.32V253H39.5699C42.9699 253 44.4399 251.23 44.4399 247.38C44.4399 244.13 42.9699 242.38 40.4399 242.38C40.2899 242.38 39.6999 242.38 38.9599 242.45V229.65C39.7651 229.504 40.5817 229.431 41.3999 229.43C49.1599 229.43 53.9699 235.57 53.9699 248.13C53.9699 261.36 48.0599 267.65 38.3699 267.65H16.8899V253C19.2128 253.282 21.55 253.429 23.8899 253.44V253.37C19.3099 252.26 16.3499 248.64 16.3499 241.84C16.2999 232.83 20.6599 228.32 26.9499 228.32ZM30.0499 253H31.0499V245.46C31.0499 243.02 29.8699 241.62 28.0999 241.62C25.8799 241.62 24.6199 243.39 24.6199 246.35C24.5699 250.5 26.5699 253 30.0499 253Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M68.1199 276.37V291H16.8899V276.37H68.1199Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 321V335.63L43.7999 335.26V335.34C49.8599 337.03 53.9999 340.81 53.9999 347.68C53.9999 354.55 50.5999 358.91 44.2399 359.8V359.87C50.0099 361.35 53.9999 365.34 53.9999 372.22C53.9999 380.42 48.9999 384.56 40.1799 384.56H16.8899V369.94H36.4799C39.8799 369.94 41.7999 368.38 41.7999 365.58C41.7999 362.32 39.2899 360.03 34.0399 360.03H16.8899V345.4H36.4799C39.8799 345.4 41.7999 343.92 41.7999 341.04C41.7999 337.93 39.2199 335.64 33.8899 335.64H16.8899V321H53.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M26.9499 392.77C32.7099 392.77 36.9499 396.69 36.9499 405.77V417.45H39.5699C42.9699 417.45 44.4399 415.68 44.4399 411.83C44.4399 408.58 42.9699 406.83 40.4399 406.83C40.2899 406.83 39.6999 406.83 38.9599 406.9V394.1C39.7651 393.954 40.5817 393.881 41.3999 393.88C49.1599 393.88 53.9699 400.02 53.9699 412.58C53.9699 425.81 48.0599 432.1 38.3699 432.1H16.8899V417.46C19.2128 417.742 21.55 417.889 23.8899 417.9V417.83C19.3099 416.72 16.3499 413.1 16.3499 406.3C16.2999 397.28 20.6599 392.77 26.9499 392.77ZM30.0499 417.46H31.0499V409.92C31.0499 407.48 29.8699 406.08 28.0999 406.08C25.8799 406.08 24.6199 407.85 24.6199 410.81C24.5699 415 26.5699 417.46 30.0499 417.46V417.46Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 440.74V455.38L43.2099 454.93V455.08C49.7099 456.56 53.9999 460.03 53.9999 467.08C53.9999 474.77 49.2699 478.47 39.4399 478.47C36.7799 478.47 33.0799 478.25 31.0099 478.1V465.5C32.7099 465.65 34.7099 465.65 35.8199 465.65C40.0299 465.65 41.7299 464.1 41.7299 461.36C41.7299 457.74 38.7299 455.36 32.1899 455.36H16.8899V440.74H53.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M14.31 528C6.1 528 1 520.91 1 506.79C1 491.86 4.7 485.21 10.24 485.21C13.57 485.21 15.93 487.35 16.3 491.49H16.45C17.0819 490.36 18.0054 489.421 19.1241 488.77C20.2428 488.12 21.5158 487.781 22.81 487.79C24.4761 487.719 26.114 488.236 27.4374 489.251C28.7609 490.265 29.6856 491.713 30.05 493.34H30.12C31.0149 491.243 32.5282 489.468 34.4578 488.253C36.3875 487.038 38.6419 486.44 40.92 486.54C48.82 486.54 54 492.82 54 505.09C54.1017 508.877 53.449 512.647 52.08 516.18H52.15C52.6163 515.492 53.2295 514.916 53.9455 514.494C54.6615 514.072 55.4623 513.815 56.29 513.74C59.1 513.74 61.09 516.47 61.09 522.24C61.109 523.699 61.012 525.157 60.8 526.6H52C52.0897 525.937 52.1398 525.269 52.15 524.6C52.15 522.38 51.26 521.35 49.79 521.35C48.9531 521.432 48.1318 521.63 47.35 521.94C45.4043 523.088 43.179 523.677 40.92 523.64C32.92 523.64 27.92 517.28 27.92 505.09C27.9237 503.211 28.0473 501.333 28.29 499.47C27.7428 499.177 27.1308 499.025 26.51 499.03C25.18 499.03 24.37 500.03 24.37 501.76V513.81C24.36 523.38 20.37 528 14.31 528ZM11.57 513.54C13.2 513.54 14.09 512.29 14.09 508.89V503.12C14.09 500.98 13.09 499.58 11.42 499.58C9.28 499.58 8.1 502.09 8.1 506.67C8.1 511.25 9.36 513.54 11.57 513.54ZM40.92 500.31C37.44 500.31 35.59 502.09 35.59 505.12C35.59 508.15 37.44 509.92 40.92 509.92C44.4 509.92 46.24 508.22 46.24 505.12C46.24 502.02 44.46 500.31 40.92 500.31Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 535.94V550.57H16.8899V535.94H53.4099ZM66.7099 535.94V550.65H56.7099V535.94H66.7099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M53.4099 559.81V574.45L43.6499 574V574.15C50.0099 575.85 53.9999 579.84 53.9999 587.97C53.9999 596.97 48.8999 601.8 39.7299 601.8H16.8899V587.16H36.0399C40.0399 587.16 41.7999 585.02 41.7999 581.4C41.7999 577.11 39.2199 574.45 33.8899 574.45H16.8899V559.81H53.4099Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M26.9499 610C32.7099 610 36.9499 613.92 36.9499 623V634.68H39.5699C42.9699 634.68 44.4399 632.9 44.4399 629.06C44.4399 625.81 42.9699 624.06 40.4399 624.06C39.9455 624.06 39.4515 624.087 38.9599 624.14V611.33C39.7644 611.179 40.5814 611.106 41.3999 611.11C49.1599 611.11 53.9699 617.24 53.9699 629.81C53.9699 643.04 48.0599 649.32 38.3699 649.32H16.8899V634.69C19.2128 634.972 21.55 635.119 23.8899 635.13V635.06C19.3099 633.95 16.3499 630.33 16.3499 623.53C16.2999 614.51 20.6599 610 26.9499 610ZM30.0499 634.69H31.0499V627.15C31.0499 624.71 29.8699 623.3 28.0999 623.3C25.8799 623.3 24.6199 625.08 24.6199 628.03C24.5699 632.17 26.5699 634.69 30.0499 634.69V634.69Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M68.1199 658V672.64H16.8899V658H68.1199Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  </svg>
)


export const LogoVerticalLeft = ({ maxHeight="98vh", maxWidth="100%" }) => (
  <svg width="auto" height="98vh" style={{maxHeight, maxWidth, width: 'auto'}} viewBox="0 0 79 720" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39.9198 696C28.3198 696 20.9998 688.38 20.9998 676C20.9998 663.62 27.2098 657.15 37.7798 657.15L37.7798 669.71C33.5698 669.79 31.4198 671.86 31.4198 675.26C31.4198 679.26 34.1598 681.39 39.8498 681.39C45.5398 681.39 48.2798 679.1 48.2798 675.48C48.2798 671.86 46.2098 669.71 42.0698 669.64L42.0698 657.15C52.8598 657.15 58.6998 663.8 58.6998 676.44C58.6998 689.08 51.0798 696 39.9198 696Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M41.9199 610.14L41.9199 634.75C47.1699 634.38 49.1599 632.24 49.1599 628.25C49.1599 624.92 47.8299 622.85 44.7299 622.63V610.14C53.3799 610.29 58.7299 616.72 58.7299 628.92C58.7299 643.03 51.1899 649.46 40.2499 649.46C27.6799 649.46 21.0299 641.55 21.0299 628.77C21.0299 617.16 26.7999 610.14 38.5499 610.14H41.9199ZM34.9199 622.63C31.5899 622.63 29.9199 624.7 29.9199 628.25C29.9199 631.8 31.5499 633.87 35.9199 634.53V622.63H34.9199Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 601.79V587.16L31.3499 587.6V587.45C24.9899 585.75 20.9999 581.76 20.9999 573.63C20.9999 564.63 26.0999 559.81 35.2699 559.81H58.1099V574.44H38.9599C34.9599 574.44 33.1999 576.59 33.1999 580.21C33.1999 584.5 35.7799 587.16 41.1099 587.16H58.1099V601.79H21.5899Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M45.2398 547.91H32.6098V552.91H21.6098V548.91C21.6098 546.91 20.6098 546.03 17.9898 545.81L13.3298 545.29V533.29H21.5898L21.5898 522.5H32.5898V533.27H43.8398C46.8398 533.27 47.8398 531.87 47.8398 527.73C47.8379 526.12 47.6906 524.514 47.3998 522.93H57.8798C58.4592 525.699 58.7341 528.522 58.6998 531.35C58.6998 543.62 53.5198 547.91 45.2398 547.91Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 514.21V499.57L31.7899 500V499.85C25.2899 498.37 20.9999 494.85 20.9999 487.85C20.9999 480.17 25.7299 476.47 35.5599 476.47C38.2199 476.47 41.9199 476.69 43.9899 476.84L43.9899 489.45C42.2899 489.3 40.2899 489.3 39.1799 489.3C34.9699 489.3 33.2699 490.85 33.2699 493.59C33.2699 497.21 36.2699 499.59 42.8099 499.59H58.1099V514.21H21.5899Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M48.0498 468.68C42.2898 468.68 38.0498 464.76 38.0498 455.68V444H35.4298C32.0298 444 30.5598 445.77 30.5598 449.62C30.5598 452.87 32.0298 454.62 34.5598 454.62C34.7098 454.62 35.2998 454.62 36.0398 454.55V467.35C35.2347 467.496 34.4181 467.57 33.5998 467.57C25.8398 467.57 21.0298 461.43 21.0298 448.87C21.0298 435.64 26.9398 429.35 36.6298 429.35H58.1098V444C55.787 443.718 53.4497 443.572 51.1098 443.56V443.63C55.6898 444.74 58.6498 448.36 58.6498 455.16C58.6998 464.17 54.3398 468.68 48.0498 468.68ZM44.9498 444H43.9498V451.54C43.9498 453.98 45.1298 455.38 46.8998 455.38C49.1198 455.38 50.3798 453.61 50.3798 450.65C50.4298 446.5 48.4298 444 44.9498 444Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M6.87987 420.63V406H58.1099V420.63H6.87987Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 376V361.37L31.1999 361.74V361.66C25.1399 359.97 20.9999 356.19 20.9999 349.32C20.9999 342.45 24.3999 338.09 30.7599 337.2V337.13C24.9899 335.65 20.9999 331.66 20.9999 324.78C20.9999 316.58 25.9999 312.44 34.8199 312.44H58.1099V327.06H38.5199C35.1199 327.06 33.1999 328.62 33.1999 331.42C33.1999 334.68 35.7099 336.97 40.9599 336.97H58.1099V351.6H38.5199C35.1199 351.6 33.1999 353.08 33.1999 355.96C33.1999 359.07 35.7799 361.36 41.1099 361.36H58.1099V376H21.5899Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M48.0498 304.23C42.2898 304.23 38.0498 300.31 38.0498 291.23V279.55H35.4298C32.0298 279.55 30.5598 281.32 30.5598 285.17C30.5598 288.42 32.0298 290.17 34.5598 290.17C34.7098 290.17 35.2998 290.17 36.0398 290.1V302.9C35.2347 303.046 34.4181 303.12 33.5998 303.12C25.8398 303.12 21.0298 296.98 21.0298 284.42C21.0298 271.19 26.9398 264.9 36.6298 264.9H58.1098V279.54C55.787 279.258 53.4497 279.111 51.1098 279.1V279.17C55.6898 280.28 58.6498 283.9 58.6498 290.7C58.6998 299.72 54.3398 304.23 48.0498 304.23ZM44.9498 279.54H43.9498V287.08C43.9498 289.52 45.1298 290.92 46.8998 290.92C49.1198 290.92 50.3798 289.15 50.3798 286.19C50.4298 282 48.4298 279.54 44.9498 279.54V279.54Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 256.26V241.62L31.7899 242.07V241.92C25.2899 240.44 20.9999 236.97 20.9999 229.92C20.9999 222.23 25.7299 218.53 35.5599 218.53C38.2199 218.53 41.9199 218.75 43.9899 218.9L43.9899 231.5C42.2899 231.35 40.2899 231.35 39.1799 231.35C34.9699 231.35 33.2699 232.9 33.2699 235.64C33.2699 239.26 36.2699 241.64 42.8099 241.64H58.1099V256.26H21.5899Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M60.6898 169C68.8998 169 73.9998 176.09 73.9998 190.21C73.9998 205.14 70.2998 211.79 64.7598 211.79C61.4298 211.79 59.0698 209.65 58.6998 205.51H58.5498C57.9179 206.64 56.9944 207.579 55.8756 208.23C54.7569 208.881 53.484 209.219 52.1898 209.21C50.5237 209.281 48.8858 208.764 47.5623 207.749C46.2389 206.735 45.3141 205.288 44.9498 203.66H44.8798C43.9849 205.758 42.4716 207.532 40.5419 208.747C38.6122 209.962 36.3579 210.56 34.0798 210.46C26.1798 210.46 20.9998 204.18 20.9998 191.91C20.8981 188.123 21.5508 184.353 22.9198 180.82H22.8498C22.3835 181.508 21.7702 182.084 21.0543 182.506C20.3383 182.928 19.5375 183.186 18.7098 183.26C15.8998 183.26 13.9098 180.53 13.9098 174.76C13.8908 173.302 13.9877 171.844 14.1998 170.4H22.9998C22.91 171.063 22.8599 171.731 22.8498 172.4C22.8498 174.62 23.7398 175.65 25.2098 175.65C26.0467 175.569 26.868 175.37 27.6498 175.06C29.5955 173.912 31.8207 173.324 34.0798 173.36C42.0798 173.36 47.0798 179.72 47.0798 191.91C47.0761 193.79 46.9525 195.667 46.7098 197.53C47.2569 197.824 47.869 197.975 48.4898 197.97C49.8198 197.97 50.6298 196.97 50.6298 195.24V183.19C50.6398 173.62 54.6298 169 60.6898 169ZM63.4298 183.46C61.7998 183.46 60.9098 184.71 60.9098 188.11V193.88C60.9098 196.02 61.9098 197.42 63.5798 197.42C65.7198 197.42 66.8998 194.91 66.8998 190.33C66.8998 185.75 65.6398 183.46 63.4298 183.46ZM34.0798 196.69C37.5598 196.69 39.4098 194.91 39.4098 191.88C39.4098 188.85 37.5598 187.08 34.0798 187.08C30.5998 187.08 28.7598 188.78 28.7598 191.88C28.7598 194.98 30.5398 196.69 34.0798 196.69Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 161.06V146.43H58.1099V161.06H21.5899ZM8.28987 161.06V146.35H18.2899V161.06H8.28987Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M21.5899 137.19V122.55L31.3499 123V122.85C24.9899 121.15 20.9999 117.16 20.9999 109.03C20.9999 100.03 26.0999 95.2003 35.2699 95.2003H58.1099V109.84H38.9599C34.9599 109.84 33.1999 111.98 33.1999 115.6C33.1999 119.89 35.7799 122.55 41.1099 122.55H58.1099V137.19H21.5899Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M48.0498 87.0003C42.2898 87.0003 38.0498 83.0803 38.0498 74.0003V62.3203H35.4298C32.0298 62.3203 30.5598 64.1003 30.5598 67.9403C30.5598 71.1903 32.0298 72.9403 34.5598 72.9403C35.0543 72.94 35.5483 72.9133 36.0398 72.8603V85.6703C35.2354 85.821 34.4183 85.8947 33.5998 85.8903C25.8398 85.8903 21.0298 79.7603 21.0298 67.1903C21.0298 53.9603 26.9398 47.6803 36.6298 47.6803L58.1098 47.6803V62.3103C55.787 62.0285 53.4497 61.8815 51.1098 61.8703V61.9403C55.6898 63.0503 58.6498 66.6703 58.6498 73.4703C58.6998 82.4903 54.3398 87.0003 48.0498 87.0003ZM44.9498 62.3103H43.9498V69.8503C43.9498 72.2903 45.1298 73.7003 46.8998 73.7003C49.1198 73.7003 50.3798 71.9203 50.3798 68.9703C50.4298 64.8303 48.4298 62.3103 44.9498 62.3103V62.3103Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M6.87987 39.0003V24.3603L58.1099 24.3603V39.0003L6.87987 39.0003Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  </svg>
)

export const LogoHorizontal = () => (
  <svg width="673" height="68" viewBox="0 0 673 68" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.500244 33.4202C0.500244 21.8202 8.12024 14.5002 20.5002 14.5002C32.8802 14.5002 39.3502 20.7102 39.3502 31.2802H26.7902C26.7102 27.0702 24.6402 24.9202 21.2402 24.9202C17.2402 24.9202 15.1102 27.6602 15.1102 33.3502C15.1102 39.0402 17.4002 41.7802 21.0202 41.7802C24.6402 41.7802 26.7902 39.7102 26.8602 35.5702H39.3502C39.3502 46.3602 32.7002 52.2002 20.0602 52.2002C7.42024 52.2002 0.500244 44.5802 0.500244 33.4202Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M86.3602 35.4202H61.7502C62.1202 40.6702 64.2602 42.6602 68.2502 42.6602C71.5802 42.6602 73.6502 41.3302 73.8702 38.2302H86.3602C86.2102 46.8802 79.7802 52.2302 67.5802 52.2302C53.4702 52.2302 47.0402 44.6902 47.0402 33.7502C47.0402 21.1802 54.9502 14.5302 67.7302 14.5302C79.3402 14.5302 86.3602 20.3002 86.3602 32.0502V35.4202ZM73.8702 28.4202C73.8702 25.0902 71.8002 23.4202 68.2502 23.4202C64.7002 23.4202 62.6302 25.0502 61.9702 29.4202H73.8702V28.4202Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M94.7102 15.0902H109.34L108.9 24.8502H109.05C110.75 18.4902 114.74 14.5002 122.87 14.5002C131.87 14.5002 136.69 19.6002 136.69 28.7702V51.6102H122.06V32.4602C122.06 28.4602 119.91 26.7002 116.29 26.7002C112 26.7002 109.34 29.2802 109.34 34.6102V51.6102H94.7102V15.0902Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M148.59 38.7401V26.1101H143.59V15.1101H147.59C149.59 15.1101 150.47 14.1101 150.69 11.4901L151.21 6.83015H163.21V15.0901H174V26.0901H163.23V37.3401C163.23 40.3401 164.63 41.3401 168.77 41.3401C170.38 41.3383 171.987 41.191 173.57 40.9001V51.3801C170.802 51.9595 167.978 52.2345 165.15 52.2001C152.88 52.2001 148.59 47.0201 148.59 38.7401Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M182.29 15.0902H196.93L196.5 25.2902H196.65C198.13 18.7902 201.65 14.5002 208.65 14.5002C216.33 14.5002 220.03 19.2302 220.03 29.0602C220.03 31.7202 219.81 35.4202 219.66 37.4902H207.05C207.2 35.7902 207.2 33.7902 207.2 32.6802C207.2 28.4702 205.65 26.7702 202.91 26.7702C199.29 26.7702 196.91 29.7702 196.91 36.3102V51.6102H182.29V15.0902Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M227.82 41.5502C227.82 35.7902 231.74 31.5502 240.82 31.5502H252.5V28.9302C252.5 25.5302 250.73 24.0602 246.88 24.0602C243.63 24.0602 241.88 25.5302 241.88 28.0602C241.88 28.2102 241.88 28.8002 241.95 29.5402H229.15C229.004 28.735 228.931 27.9185 228.93 27.1002C228.93 19.3402 235.07 14.5302 247.63 14.5302C260.86 14.5302 267.15 20.4402 267.15 30.1302V51.6102H252.5C252.782 49.2873 252.929 46.9501 252.94 44.6102H252.87C251.76 49.1902 248.14 52.1502 241.34 52.1502C232.33 52.2002 227.82 47.8402 227.82 41.5502ZM252.5 38.4502V37.4502H244.96C242.52 37.4502 241.12 38.6302 241.12 40.4002C241.12 42.6202 242.89 43.8802 245.85 43.8802C250 43.9302 252.5 41.9302 252.5 38.4502Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M275.87 0.380238H290.5V51.6102H275.87V0.380238Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M320.5 15.0902H335.13L334.76 24.7002H334.84C336.53 18.6402 340.31 14.5002 347.18 14.5002C354.05 14.5002 358.41 17.9002 359.3 24.2602H359.37C360.85 18.4902 364.84 14.5002 371.72 14.5002C379.92 14.5002 384.06 19.5002 384.06 28.3202V51.6102H369.44V32.0202C369.44 28.6202 367.88 26.7002 365.08 26.7002C361.82 26.7002 359.53 29.2102 359.53 34.4602V51.6102H344.9V32.0202C344.9 28.6202 343.42 26.7002 340.54 26.7002C337.43 26.7002 335.14 29.2802 335.14 34.6102V51.6102H320.5V15.0902Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M392.27 41.5502C392.27 35.7902 396.19 31.5502 405.27 31.5502H416.95V28.9302C416.95 25.5302 415.18 24.0602 411.33 24.0602C408.08 24.0602 406.33 25.5302 406.33 28.0602C406.33 28.2102 406.33 28.8002 406.4 29.5402H393.6C393.454 28.735 393.381 27.9185 393.38 27.1002C393.38 19.3402 399.52 14.5302 412.08 14.5302C425.31 14.5302 431.6 20.4402 431.6 30.1302V51.6102H416.96C417.242 49.2873 417.389 46.9501 417.4 44.6102H417.33C416.22 49.1902 412.6 52.1502 405.8 52.1502C396.78 52.2002 392.27 47.8402 392.27 41.5502ZM416.96 38.4502V37.4502H409.42C406.98 37.4502 405.58 38.6302 405.58 40.4002C405.58 42.6202 407.35 43.8802 410.31 43.8802C414.5 43.9302 416.96 41.9302 416.96 38.4502V38.4502Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M440.24 15.0902H454.88L454.43 25.2902H454.58C456.06 18.7902 459.53 14.5002 466.58 14.5002C474.27 14.5002 477.97 19.2302 477.97 29.0602C477.97 31.7202 477.75 35.4202 477.6 37.4902H465C465.15 35.7902 465.15 33.7902 465.15 32.6802C465.15 28.4702 463.6 26.7702 460.86 26.7702C457.24 26.7702 454.86 29.7702 454.86 36.3102V51.6102H440.24V15.0902Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M527.5 54.1901C527.5 62.4001 520.41 67.5001 506.29 67.5001C491.36 67.5001 484.71 63.8001 484.71 58.2601C484.71 54.9301 486.85 52.5701 490.99 52.2001V52.0501C489.861 51.4182 488.921 50.4947 488.271 49.376C487.62 48.2573 487.281 46.9844 487.29 45.6901C487.219 44.0241 487.736 42.3861 488.751 41.0627C489.765 39.7393 491.213 38.8145 492.84 38.4501V38.3801C490.743 37.4852 488.968 35.972 487.753 34.0423C486.538 32.1126 485.941 29.8583 486.04 27.5801C486.04 19.6801 492.32 14.5001 504.59 14.5001C508.378 14.3985 512.147 15.0511 515.68 16.4201V16.3501C514.992 15.8839 514.416 15.2706 513.994 14.5546C513.572 13.8386 513.315 13.0379 513.24 12.2101C513.24 9.40014 515.97 7.41014 521.74 7.41014C523.199 7.39113 524.657 7.4881 526.1 7.70013V16.5001C525.437 16.4104 524.769 16.3603 524.1 16.3501C521.88 16.3501 520.85 17.2401 520.85 18.7101C520.932 19.547 521.13 20.3684 521.44 21.1501C522.588 23.0959 523.177 25.3211 523.14 27.5801C523.14 35.5801 516.78 40.5801 504.59 40.5801C502.711 40.5765 500.834 40.4529 498.97 40.2101C498.677 40.7573 498.526 41.3693 498.53 41.9901C498.53 43.3201 499.53 44.1301 501.26 44.1301H513.31C522.88 44.1401 527.5 48.1301 527.5 54.1901ZM513.04 56.9301C513.04 55.3001 511.79 54.4101 508.39 54.4101H502.62C500.48 54.4101 499.08 55.4101 499.08 57.0801C499.08 59.2201 501.59 60.4001 506.17 60.4001C510.75 60.4001 513.04 59.1401 513.04 56.9301ZM499.81 27.5801C499.81 31.0601 501.59 32.9101 504.62 32.9101C507.65 32.9101 509.42 31.0601 509.42 27.5801C509.42 24.1001 507.72 22.2601 504.62 22.2601C501.52 22.2601 499.81 24.0401 499.81 27.5801Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M535.44 15.0902H550.07V51.6102H535.44V15.0902ZM535.44 1.79024H550.15V11.7902H535.44V1.79024Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M559.31 15.0902H573.95L573.5 24.8502H573.65C575.35 18.4902 579.34 14.5002 587.47 14.5002C596.47 14.5002 601.3 19.6002 601.3 28.7702V51.6102H586.66V32.4602C586.66 28.4602 584.52 26.7002 580.9 26.7002C576.61 26.7002 573.95 29.2802 573.95 34.6102V51.6102H559.31V15.0902Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M609.5 41.5502C609.5 35.7902 613.42 31.5502 622.5 31.5502H634.18V28.9302C634.18 25.5302 632.4 24.0602 628.56 24.0602C625.31 24.0602 623.56 25.5302 623.56 28.0602C623.56 28.5546 623.587 29.0487 623.64 29.5402H610.83C610.679 28.7357 610.606 27.9187 610.61 27.1002C610.61 19.3402 616.74 14.5302 629.31 14.5302C642.54 14.5302 648.82 20.4402 648.82 30.1302V51.6102H634.19C634.472 49.2873 634.619 46.9501 634.63 44.6102H634.56C633.45 49.1902 629.83 52.1502 623.03 52.1502C614.01 52.2002 609.5 47.8402 609.5 41.5502ZM634.19 38.4502V37.4502H626.65C624.21 37.4502 622.8 38.6302 622.8 40.4002C622.8 42.6202 624.58 43.8802 627.53 43.8802C631.67 43.9302 634.19 41.9302 634.19 38.4502V38.4502Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  <path d="M657.5 0.380238H672.14V51.6102H657.5V0.380238Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
  </svg>
)