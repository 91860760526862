const Names = [
  "Alain Muñiz",
  "Alfonsina Martínez",
  "Amalia Martínez Sánchez",
  "Archivo General de la Nación",
  "Ayuntamiento Municipal Puerto Plata",
  "Caribbean One Airways",
  "Celeste Gil vda. Sánchez",
  "Christian Diaz Portela",
  "Claudia Vásquez",
  "Dra. Marianne Benoit Bastien (Martinica)",
  "Familia Martínez Sánchez",
  "Fundación Eduardo León Jimenes",
  "Guillermo Vásquez",
  "Gustavo Báez",
  "Inmagela Abreu",
  "Instituto de Investigaciones Estéticas del Caribe (Martinica)",
  "Jaider Orsini (Colombia)",
  "Laura Sánchez",
  "Leticia Ayuso",
  "Liliana Ramírez",
  "Luis Hidalgo",
  "Madeline  Jiménes Santil (México)",
  "María Daniela Ortega",
  "Rafael Vásquez",
  "Raúl Gabriel González",
  "Sharelly Emanuelson (Curazao)",
  "Thaina Corvington (Haití)",
  "Theowaldo Perna (Venezuela)",
  "Yina Jiménez Suriel",
]

export default Names